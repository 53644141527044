import "./loadingSpinner.css";

import { t } from "i18next";

type LoadingSpinnerProps = {
  variant?: "dark" | "light";
}

const LoadingSpinner = ({variant = "dark"}: LoadingSpinnerProps) => {
  return (
    <div role="status" className={`spinner spinner-variant-${variant}`}>
      <span className="visually-hidden">{t("core.status.loading.label_dots")}</span>
    </div>
  );
};

export default LoadingSpinner;