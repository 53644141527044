import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import TimePicker from "react-time-picker";

import Field from "../Field";

import type { FieldValues, UseFormRegister, Control } from "react-hook-form";
import type { CoreFieldProps } from "../types";

import "./timePickerField.css";

interface ControllerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
}

interface FunctionProps {
  isMobileDevice: () => boolean;
}

type InputProps<T extends FieldValues> = CoreFieldProps<T> & (ControllerProps & FunctionProps) & {
  locale: string;
  placeholder?: string;
  defaultValue?: string;
  register: UseFormRegister<T>;
};

export default function TimePickerField<T extends FieldValues>(props: InputProps<T>) {
  const {
    name,
    locale,
    disabled = false,
    isSubmitted = false,
    isValid = undefined,
    isInvalid = undefined,
    control,
    register,
    getFieldState,
    isMobileDevice,
  } = props;
  return (
    <Field {...props}>
      <div className="flex-fill">
        { isMobileDevice()
          // If the user is on a mobile device, use the native time input
          ? <Form.Control
            type="time"
            id={`${name}-native`}
            isValid={isValid !== undefined ? isValid : isSubmitted === true && getFieldState(name)?.invalid === false}
            isInvalid={isInvalid !== undefined ? isInvalid : isSubmitted === true && getFieldState(name)?.invalid === true}
            aria-describedby={`${name}HelpText`} // This links the help text to the input field for screen readers
            disabled={disabled}
            className="native-time-input"
            {...register(name, { required: true })}
          />
          : <Controller
            control={control}
            name={name}
            rules={{ required: true }}
            render={({
              field: { onChange, value, name },
            }) => (
              <TimePicker
                className={`
                  d-block
                  ${isValid !== undefined ? isValid : isSubmitted === true && getFieldState(name)?.invalid === false ? "time-picker-is-valid" : ""}
                  ${isInvalid !== undefined ? isInvalid : isSubmitted === true && getFieldState(name)?.invalid === true ? "time-picker-is-invalid" : ""}
                `}
                locale={locale}
                onChange={(e) => {(e != null) && onChange(e);}}
                value={value}
                name={name}
                disabled={disabled}
                clearIcon={null}
                disableClock
              />
            )}
          />
        }
      </div>
    </Field>
  );
}