import { t } from "i18next";
import "./statusBadge.css";

export type Status = "Available" 
| "Preparing" 
| "Charging" 
| "SuspendedEVSE" 
| "SuspendedEV" 
| "Finishing" 
| "Unavailable" 
| "Faulted" 
| "Unknown" 
| "Downloading"
| "Offline"
| "RebootAfterReset"
| "ChargeDeniedInvalid"
| "ChargeDeniedMaintenance"
| undefined;

type StatusBadgeProps = {
  status: Status;
  showStandardName?: boolean;
  size?: "sm" | "lg" | "xl";
};

export default function StatusBadge({
  status,
  showStandardName = false,
  size = "lg"
}: StatusBadgeProps) {
  switch (status) {
  case "Available":
    return <span className={`status-badge-available status-badge-${size}`}>{t("core.status.available")}</span>;
  case "Preparing":
    return <span className={`status-badge-preparing status-badge-${size}`}>{showStandardName ? t("core.status.preparing") : t("core.status.waiting_for_auth")}</span>;
  case "Charging":
    return <span className={`status-badge-charging status-badge-${size}`}>{t("core.status.charging")}</span>;
  case "SuspendedEVSE":
    return <span className={`status-badge-suspendedEVSE status-badge-${size}`}>{t("core.status.suspendedevse")}</span>;
  case "SuspendedEV":
    return <span className={`status-badge-suspendedEV status-badge-${size}`}>{t("core.status.suspendedev")}</span>;
  case "Finishing":
    return <span className={`status-badge-finishing status-badge-${size}`}>{showStandardName ? t("core.status.finishing") : t("core.electrical.unplug_cable")}</span>;
  case "Unavailable":
    return <span className={`status-badge-unavailable status-badge-${size}`}>{t("core.status.unavailable")}</span>;
  case "Faulted":
    return <span className={`status-badge-faulted status-badge-${size}`}>{t("core.status.faulted")}</span>;
  case "Downloading":
    return <span className={`status-badge-downloading status-badge-${size}`}>{t("core.status.downloading")}</span>;
  case "Offline": 
    return <span className={`status-badge-offline status-badge-${size}`}>{t("core.status.offline")}</span>;
  case "RebootAfterReset":
    return <span className={`status-badge-rebootAfterReset status-badge-${size}`}>{t("core.status.restarting")}</span>;
  default:
    return <span className={`status-badge-unknown status-badge-${size}`}>{t("core.status.unknown")}</span>;
  }
}